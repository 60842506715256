import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import headerImage from "../images/editorial/careers/careers-banner.png"

import CareersVector from "../images/editorial/careers/careers.svg"
import AgileVector from "../images/editorial/careers/agile.svg"

import CommunityVector from "../images/editorial/careers/community.svg"

import InvestVector from "../images/editorial/careers/invest-in-you.svg"

import TeamVector from "../images/editorial/careers/team.svg"

import VoiceVector from "../images/editorial/careers/voice-heard.svg"

import Best2019Vector from "../images/editorial/careers/TWP_OrangeCounty_2019.svg"

import CheckVector from "../images/editorial/careers/check-mark.svg"

import ChallengeVector from "../images/editorial/careers/challenge-yourself.svg"






const SecondPage = () => (
  <Layout styleClass="careers">
    <SEO title="Careers at MSM" description="We’re making the business of healthcare better. Just Us." />

    <section className="container ctr-headline ctr-potential ctr-newframework ctr-fifty" id="sct-newframework" style={{ 'background': '#ecfaf9', 'color': '#4A4D57' }}>
      <div className="frame-image">
        <article>
          <h2>
            We’re making the business of <wbr /> healthcare better. <br />

            <span>Join us. </span>
          </h2>
        </article>
        <div className="svg">
          <img src={headerImage} />
        </div>
      </div>

    </section>

    <section className="container ctr-potential ctr-newframework" id="sct-newframework" style={{ 'background': 'white', 'color': '#4A4D57' }}>
      <div className="svg">
        <img src={CareersVector} />

      </div>
      <div ><h2>Develop your talents in our outstanding culture.</h2><p>Healthcare is evolving fast—and MSM is at the forefront of this dynamic, all-important industry.  We’re building a new vision for medical practice management—making it easier for physicians to reach their potential. Our secret sauce has always been the passion of our people—and we’re always looking for like-minded team members.</p>

        <p>MSM doesn’t merely offer jobs — with many of our employees staying ten or more years, we offer a nurturing environment to grow your career. Our employee promise is to create a sustainable workplace where our team can thrive, offer unparalleled benefits, and invest in your growth.
</p></div>
    </section>
    <section className="ctrinfoGraphicContainer container grid-format">
      <h3>As the pulse of our company, you’ll:</h3>
      <div className="content">

        <article className="ctrInfoGraphicTile">
          <div className="article-content">
            <h3>Dive headfirst into the most agile team in the field.</h3>
            <p>At MSM, we don’t wait for change to happen. We move from inkling to idea to action quickly, executing with finesse—and you will, too. </p>
          </div>
          <div className="svg">
            <img src={AgileVector} />
          </div>
        </article>
        <article className="ctrInfoGraphicTile">
          <div className="article-content">
            <h3>Challenge yourself daily.</h3>
            <p>As an industry innovator, we don’t follow blueprints—we create our own. You’ll push yourself to imagine breakthrough products, solve problems creatively, and consistently work outside your comfort zone.
</p>
          </div>
          <div className="svg">
            <img src={ChallengeVector} />
          </div>
        </article><article className="ctrInfoGraphicTile">
          <div className="article-content">
            <h3>Invest in a company that invests in you.</h3>
            <p>We pride ourselves on being a conscientious employer. MSM employees help medical groups attain their potential, and MSM helps you reach yours with a focus on growth from day one.</p>
          </div>
          <div className="svg">
            <img src={InvestVector} />
          </div>
        </article>
        <article className="ctrInfoGraphicTile">
          <div className="article-content">
            <h3>Cultivate the community.</h3>
            <p>Through our clients, we contribute to healthier, happier, and more resilient communities throughout the Orange County area. We also work with local nonprofits to raise money for relevant causes. </p>
          </div>
          <div className="svg">
            <img src={CommunityVector} />
          </div>
        </article>
        <article className="ctrInfoGraphicTile">
          <div className="article-content">
            <h3>Unite as a team. </h3>
            <p>At MSM, we have a singular focus: providing medical practices with the support they need to soar. We do this as one cohesive unit, knowing that collaboration is what makes us strongest.</p>
          </div>
          <div className="svg">
            <img src={TeamVector} />
          </div>
        </article>
        <article className="ctrInfoGraphicTile">
          <div className="article-content">
            <h3>Make Your Voice Heard. </h3>
            <p>From our CEO or our newest employee, we take everyone’s input seriously. We host team lunches and open forums to share ideas and cultivate each others’ success.</p>
          </div>
          <div className="svg">
            <img src={VoiceVector} />
          </div>
        </article>
      </div>
    </section>

    <section className="container ctr-checklist" style={{
      'background': '#f6f7fa'
    }}>
      <div className="content">
        <div className="header">
          <h3>Thrive in an award-winning
workplace</h3>
          <p>Our highly competitive benefits helped us win consecutive “The Orange County Register’s Top Workplaces” titles. These include:
</p>

        </div>
        <div className="svg">
          <img src={Best2019Vector} />
        </div>

        <ul className="checklist">
          <li>
            <div className="icon svg">
              <img src={CheckVector} />
            </div>
            100% employer-covered healthcare
    </li>
          <li>
            <div className="icon svg">
              <img src={CheckVector} />
            </div>
            Amazing work environment
    </li>
          <li>
            <div className="icon svg">
              <img src={CheckVector} />
            </div>
            Incentive compensation program
    </li>
          <li>
            <div className="icon svg">
              <img src={CheckVector} />
            </div>
            Extensive PTO and birthday time off
    </li>
          <li>
            <div className="icon svg">
              <img src={CheckVector} />
            </div>
            401K (we match)
    </li>
          <li>
            <div className="icon svg">
              <img src={CheckVector} />
            </div>
            Community involvement and celebrations
    </li>
        </ul>
      </div>
    </section>


    <section className="container job-postings">

      <div className="header">
        <h2>Join our team.</h2>
        <p>
          You can play a valuable role in developing the future of practice management.
    </p>
      </div>
      <link href="/careers.css" rel="stylesheet" />

      <div id="grnhse_app" className="jobsWindow">
        <iframe id="grnhse_iframe" width="100%" frameBorder="0" scrolling="no" allow="geolocation" title="Greenhouse Job Board" src="https://boards.greenhouse.io/embed/job_board?for=msminc&amp;b=http%3A%2F%2Fwww.msmnet.com%2Fcareers%2Fopen-positions%2F" height="752"></iframe>
      </div>

    </section>


  </Layout >
)

export default SecondPage
